import React, { createContext, useState, FC, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { defaultBreakpoints } from "styled-media-query";

type Props = {
  config: {
    desktop: {
      leaderboard: boolean;
      native: boolean;
      taboola: boolean;
      sidebar: boolean;
      sticky: boolean;
      article_freq: number;
      article_limit: number;
      liveblog_freq: number;
      liveblog_limit: number;
    };
    mobile: {
      leaderboard: boolean;
      native: boolean;
      taboola: boolean;
      sidebar: boolean;
      sticky: boolean;
      article_freq: number;
      article_limit: number;
      liveblog_freq: number;
      liveblog_limit: number;
    };
  };
  disableAllAds?: boolean;
  children: any;
};

export interface addTypes {
  sidebar: boolean;
  sticky: boolean;
  article_freq: number;
  article_limit: number;
  liveblog_freq: number;
  liveblog_limit: number;
  native: boolean;
  taboola: boolean;
  leaderboard: boolean;
  disableAllAds?: boolean;
  setMpuCount: any;
  mpuCount: any;
  count: any
}
export const Context = createContext({} as any);

export const Provider: FC<Props> = ({ children, config, disableAllAds= false }) => {
  const desktop = useMediaQuery({
    query: `(min-width: ${defaultBreakpoints.medium})`,
  });
  const [mpuCount, setMpuCount] = useState(2);
  const count = useRef(1)

  const [addConfigDesktop] = useState<any>({
    leaderboard: config?.desktop?.leaderboard
      ? config.desktop?.leaderboard
      : true,
    native: config?.desktop?.native ? config.desktop?.native : true,
    taboola: config?.desktop?.taboola ? config.desktop?.taboola : true,
    sidebar: config?.desktop?.sidebar ? config.desktop?.sidebar : true,
    sticky: config?.desktop?.sticky ? config.desktop?.sticky : true,
    article_freq: config?.desktop?.article_freq
      ? config.desktop?.article_freq
      : 4,
    article_limit: config?.desktop?.article_limit
      ? config.desktop?.article_limit
      : 2,
    liveblog_freq: config?.desktop?.liveblog_freq
      ? config.desktop?.liveblog_freq
      : 3,
    liveblog_limit: config?.desktop?.liveblog_limit
      ? config.desktop?.liveblog_limit
      : 1,
  });

  const [addConfigMobile] = useState<any>({
    native: config?.mobile?.native ? config?.mobile?.native : true,
    taboola: config?.mobile?.taboola ? config?.mobile?.taboola : true,
    leaderboard: config?.mobile?.leaderboard ? config?.mobile?.leaderboard : true,
    article_freq: config?.mobile?.article_freq ? config?.mobile?.article_freq : 4,
    article_limit: config?.mobile?.article_limit
      ? config?.mobile?.article_limit
      : 2,
    liveblog_freq: config?.mobile?.liveblog_freq
      ? config?.mobile?.liveblog_freq
      : 3,
    liveblog_limit: config?.mobile?.liveblog_limit
      ? config?.mobile?.liveblog_limit
      : 1,
  });



  const configContext = {
    setMpuCount: setMpuCount,
    count: count,
    mpuCount: mpuCount,
    leaderboard: desktop
      ? addConfigDesktop.leaderboard
      : addConfigMobile.leaderboard,
    sidebar: desktop ? addConfigDesktop.sidebar : true,
    sticky: desktop ? addConfigDesktop.sticky : null,
    article_freq: desktop
      ? addConfigDesktop.article_freq
      : addConfigMobile.article_freq,
    article_limit: desktop
      ? addConfigDesktop.article_limit
      : addConfigMobile.article_limit,
    liveblog_freq: desktop
      ? addConfigDesktop.liveblog_freq
      : addConfigMobile.liveblog_freq,
    liveblog_limit: desktop
      ? addConfigDesktop.liveblog_limit
      : addConfigMobile.liveblog_limit,
    native: desktop ? addConfigDesktop.native : addConfigMobile.native,
    taboola: desktop ? addConfigDesktop.taboola : addConfigMobile.taboola,
  };

  const disabledContext = {
    leaderboard: false,
    sidebar: false,
    sticky: false,
    article_freq: false,
    article_limit: false,
    liveblog_freq: false,
    liveblog_limit: false,
    native: false,
    taboola: false,
    setMpuCount: setMpuCount,
    mpuCount: mpuCount
  };


  return <Context.Provider value={disableAllAds ? disabledContext: configContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
