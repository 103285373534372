import { LayoutProps } from "common/Layout/types/Layout.types";
import { AdvertContextProvider } from "common/context/Adverts";
import { FC } from "react";
import dynamic from "next/dynamic";
import {
  fetchLayoutData,
  fetchSectionData,
  fetchJobFeed,
  renderPublication,
} from "../../lib";
import { GetServerSideProps } from "next";
import { setHeaders } from "../common/Utils/headers";

const Section = dynamic(() => import("common/Layout/Section"));

type Props = {
  data: any;
  menus: LayoutProps;
  jobs: any;
  publication: string;
  error?: string;
};
const Home: FC<Props> = ({ data, menus, jobs, error }) => {
  if (error) {
    console.log("error", error);
    return <div>An error 500 occurred on server</div>;
  }
  if (data === "err") {
    return <>there was an error</>;
  }
  if (data.data?.type === "section") {
    return (
      <AdvertContextProvider config={data.addConfig}>
        <Section jobs={jobs} menus={menus} data={data} />
      </AdvertContextProvider>
    );
  }
  return null;
};

export default Home;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { res, req } = context;
  setHeaders(req, res);
  try {
    // Extract the publication based on the request context
    const publication = renderPublication(context.req);
    let data, menus, jobs;

    // Fetch section data; if there's an error, log it and throw a more user-friendly message
    try {
      data = await fetchSectionData(publication, "");
    } catch (error: any) {
      console.error("Error fetching section data:", error.message);
      throw new Error("Failed to fetch section data.");
    }

    // Fetch layout data in a similar manner
    try {
      menus = await fetchLayoutData(publication);
    } catch (error: any) {
      console.error("Error fetching layout data:", error.message);
      throw new Error("Failed to fetch layout data.");
    }

    // Fetch job feed data
    try {
      jobs = await fetchJobFeed(publication);
    } catch (error: any) {
      console.error("Error fetching job feed:", error.message);
      throw new Error("Failed to fetch job feed.");
    }

    // Consolidate fetched data into a props object
    const props: any = { data, menus, jobs, publication };

    // Check if the fetched data indicates a "NOT_FOUND" situation, and return the 'notFound' flag if so
    if (props?.data?.data?.type === "NOT_FOUND") {
      return { notFound: true };
    }

    context.res.setHeader(
      "Cache-Tag",
      `tindle_${process.env.NEXT_PUBLIC_ENV_STATE}, ${props?.data?.data?.cacheKeys}`
    );

    // Return the props for the component to use
    return {
      props,
    };
  } catch (err: any) {
    // Catch any errors that might occur during the entire process
    console.error("Error in getServerSideProps:", err.message);

    return {
      props: {
        error: err.message || "An unexpected error occurred.",
      },
    };
  }
};
